<template>
  <article>
    <div class="hero box-quemsomos">
      <h1>Quem Somos</h1>
      <p class="subh">
        Acolhemos a população em uma parceria ética e transparente, por meio de uma gestão hospitalar séria e profissional.
      </p>
    </div>
    <section class="nossa-gestao">
      <div class="container-base">
        <h1>Gestão</h1>
        <carrossel class="lista-gestores" :items="gestores" item-width="320">
          <figure
            class="gestor"
            :class="{ 'loading': loadingGestao }"
            slot-scope="{ item: gestor }"
          >
            <img
              v-if="gestor.foto"
              class="foto-gestor"
              v-lazy="cms.getAssetUrl(gestor.foto.private_hash, {w: 420, h: 430, f: 'contain', q: 95})"
            >
            <div
              v-else
              class="foto-gestor"
            />
            <figcaption>
              <span><b>{{ gestor.nome }}</b></span><br>
              <span>{{ gestor.cargo }}</span><br>
              <span>{{ gestor.cidade }}</span>
            </figcaption>
          </figure>
        </carrossel>
      </div>
    </section>
    <section class="estatuto">
      <div class="hero box-estatuto">
        <h1>
          Estatuto
          <p class="subh">
            Ética e Transparência
          </p>
        </h1>
        <p>Clique no botão abaixo para conhecer nosso estatuto:</p>
        <br>
        <p>
          <router-link
            class="button verde"
            to="/transparencia/institucional"
          >
            Visite
          </router-link>
        </p>
      </div>
    </section>
    <section class="parceiros">
      <article class="container-base">
        <h1>
          Parceiros
          <p>A AHBB/Rede Santa Casa se orgulha de formar, com dezenas de parceiros, times comprometidos com resultados na gestão de saúde de projetos em todo o país.</p>
        </h1>
        <parceiros />
      </article>
    </section>
  </article>
</template>

<style lang="scss" scoped>
  .box-quemsomos {
    background-image: url('../assets/bg_quemsomos.jpg');
    background-image: image-set('../assets/bg_quemsomos.jpg' 1x, '../assets/bg_quemsomos@2x.jpg' 2x);
  }

  .nossa-gestao {
    margin-top: 64px;
    h1 {
      text-align: center;
    }
  }
  .lista-gestores {
    figure.gestor {
      display: flex;
      margin: 12px auto;
      width: calc(100% - 24px);
      max-width: 460px;
      height: 520px;

      flex-direction: column;

      background-color: #dfdfdf;

      .foto-gestor {
        flex: 1 0 0;
        min-height: 0;
        width: 100%;

        object-fit: cover;
        object-position: top;
      }

      figcaption {
        width: 100%;
        padding: 20px;

        flex: 0 0 auto;

        background: var(--ahbb-blue-gradient);
        color: white;

        span {
          &:empty::after {
            content: '\200D';
            display: inline;
          }
        }
      }

      &.loading {
        figcaption span {
          background-color: #fff2;
          color: transparent;
          white-space: pre-wrap;
        }
      }
    }
  }

  .estatuto {
    margin: 40px 0;
  }

  .box-estatuto {
    padding-top: 140px;
    padding-bottom: 140px;
    background-image: url('../assets/bg_estatuto.jpg');
    background-position: top;

    .subh {
      margin-top: 30px;
    }
  }

  .parceiros {
    h1 {
      text-align: center;
    }
  }

  .faleconosco {
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 16px;
    background-color: #293b590b;
  }
</style>

<script>
import cms from '@/lib/cms.js'
import Carrossel from '@/components/Carrossel.vue'
import Parceiros from '@/components/Parceiros.vue'

export default {
  name: 'QuemSomos',
  data: () => ({
    gestores: Array(13).fill().map((v, i) => ({
      nome: Array(16 + Math.floor(Math.random() * 20)).fill('_').join(''),
      cargo: Array(10 + Math.floor(Math.random() * 15)).fill('_').join(''),
      cidade: Array(10 + Math.floor(Math.random() * 25)).fill('_').join(''),
    })),
    loadingGestao: true,
    cms,
  }),
  async created() {
    this.loadingGestao = true
    this.gestores = (await cms.getItems('gestao', { fields: '*, foto.private_hash' })).data
    this.loadingGestao = false
  },
  components: {
    Carrossel,
    Parceiros,
  },
  metaInfo: {
    title: 'Quem Somos',
  },
}
</script>
