import { render, staticRenderFns } from "./QuemSomos.vue?vue&type=template&id=374d36bf&scoped=true&"
import script from "./QuemSomos.vue?vue&type=script&lang=js&"
export * from "./QuemSomos.vue?vue&type=script&lang=js&"
import style0 from "./QuemSomos.vue?vue&type=style&index=0&id=374d36bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "374d36bf",
  null
  
)

export default component.exports